import React, {useState} from "react"

function App() {

    const SvgInfo = () => {
        return <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M13 11H19.5C19.7652 11 20.0196 11.1054 20.2071 11.2929C20.3946 11.4804 20.5 11.7348 20.5 12C20.5 12.2652 20.3946 12.5196 20.2071 12.7071C20.0196 12.8946 19.7652 13 19.5 13H13V19.5C13 19.7652 12.8946 20.0196 12.7071 20.2071C12.5196 20.3946 12.2652 20.5 12 20.5C11.7348 20.5 11.4804 20.3946 11.2929 20.2071C11.1054 20.0196 11 19.7652 11 19.5V13H4.5C4.23478 13 3.98043 12.8946 3.79289 12.7071C3.60536 12.5196 3.5 12.2652 3.5 12C3.5 11.7348 3.60536 11.4804 3.79289 11.2929C3.98043 11.1054 4.23478 11 4.5 11H11V4.5C11 4.23478 11.1054 3.98043 11.2929 3.79289C11.4804 3.60536 11.7348 3.5 12 3.5C12.2652 3.5 12.5196 3.60536 12.7071 3.79289C12.8946 3.98043 13 4.23478 13 4.5V11Z"></path></svg>
    }

    return (
        <div className="">
            <div className="docBo">
                <h1>Документация по использованию панели управления партнёра Zsquare System</h1>
                <p>Данная документация предназначена для пользователей панели управления. В ней описаны основные функции, ключевые элементы интерфейса и инструкции по использованию.</p>

                <div className="section">
                    <h2>Основные функции панели управления</h2>
                    <p>Панель управления предоставляет оператору следующие основные функции:</p>
                    <ul>
                        <li>Управление подписками: создание, изменение параметров, приостановка, возобновление подписок, получение информации по отдельной подписке.</li>
                        <li>Генерация отчётов: возможность формировать отчёты по подпискам для анализа и мониторинга.</li>
                    </ul>
                    <img alt="Основные функции панели управления" className="backgroundGray" src={require("./img_help/csv-48w.png")} />
                </div>

                <div className="section">
                    <h2>1. Управление подписками</h2>
                    <p>Управление подписками осуществляется в разделе «<a href="https://partners.zsquare.ru/backoffice/subscriptions">Подписки</a>». Здесь отображаются все подписки, доступные оператору. Имеется возможность отфильтровать подписки по статусу, сроку действия, а также выполнить поиск по ID, E-mail и названию компании. Список подписок можно выгрузить в виде CSV-файла по нажатию соответствующей кнопки.</p>

                    <h3>1.1 Получение информации по подписке</h3>
                    <ul>
                        <li>Чтобы получить подробную информацию о подписке, найдите её в таблице и нажмите кнопку <i className="demo-icon icon-info">&#xe800;</i> «Подробнее о подписке».</li>
                        <li>В открывшемся окне отобразится подробная информация по подписке, включая данные о компании, срок действия и стоимость подписки, название и SKU продукта, количество лицензий, а также код активации подписки.</li>
                    </ul>

                    <h3>1.2 Создание подписки</h3>
                    <ul>
                        <li>Для создания новой подписки перейдите в раздел «<a href="https://partners.zsquare.ru/backoffice/subscriptions">Подписки</a>» и выберите <SvgInfo /> «<a href="https://partners.zsquare.ru/backoffice/editsubscription/returl/%252Fbackoffice%252Fsubscriptions/">Подключить подписку</a>».</li>
                        <li>Выберите продукт из списка. Продукты могут быть отфильтрованы по названию и коду поставщика — для этого выберите из выпадающего списка поле, по которому нужно отфильтровать, и воспользуйтесь полем «Поиск».</li>
                        <li>Укажите количество лицензий (количество устройств, на которые распространяется подписка).</li>
                        <li>При необходимости добавьте Approval Code.</li>
                        <li>Введите данные о конечном пользователе продукта: информацию о компании, включая название, ИНН, а также данные лица, уполномоченного на заключение договора. Обязательные поля отмечены символом «*».</li>
                        <li>Подтвердите создание подписки, нажав кнопку «Готово».</li>
                    </ul>

                    <h3>1.3 Приостановка подписки</h3>
                    <ul>
                        <li>Для приостановки активной подписки найдите её в таблице и нажмите кнопку <i className="demo-icon icon-cancel">&#xe803;</i> «Остановить подписку».</li>
                        <li>В открывшемся окне выведется подробная информация о подписке. Подтвердите приостановку, нажав кнопку «Остановить».</li>
                    </ul>

                    <h3>1.4 Возобновление подписки</h3>
                    <ul>
                        <li>Для возобновления остановленной подписки выберите её из списка и нажмите кнопку <i className="demo-icon icon-info">&#xe802;</i> «Возобновить подписку». В открывшемся окне отобразится подробная информация о подписке.</li>
                        <li>При необходимости введите новый Approval Code или удалите существующий. Чтобы удалить Approval Code, установите флаг «Удалить Approval Code» (при очистке содержимого поля Approval Code без установки флага текущий код сохраняется).</li>
                        <li>Подтвердите возобновление подписки, нажав кнопку «Возобновить». Подписки с периодом тарификации один день или месяц могут быть возобновлены только бессрочно. Подписки с годовым периодом тарификации могут быть возобновлены только на один год.</li>
                    </ul>

                    <h3>1.5 Изменение количества лицензий</h3>
                    <ul>
                        <li>Для изменения количества лицензий (количество устройств, на которые распространяется действие подписки) найдите её в списке и нажмите кнопку «Изменить количество лицензий».</li>
                        <li>В открывшемся окне отобразится подробная информация о подписке и текущее количество лицензий.</li>
                        <li>Введите новое количество лицензий. При выборе нового количества лицензий отобразится новая цена подписки.</li>
                        <li>Подтвердите изменение параметров подписки, нажав кнопку «Изменить».</li>
                    </ul>

                    <h3>1.6 Отправка ключа на E-mail</h3>
                    <ul>
                        <li>Панель управления партнёра предоставляет возможность отправить ключ активной подписки на электронную почту. Для этого:</li>
                        <li>Найдите нужную активную подписку в таблице.</li>
                        <li>Нажмите кнопку <i className="demo-icon icon-info">&#xe804;</i> «Отправить ключ на почту».</li>
                        <li>Укажите адрес электронной почты и нажмите кнопку «Отправить».</li>
                    </ul>
                </div>

                <div className="section">
                    <h2>2. Генерация отчётов</h2>
                    <ul>
                        <li>Для генерации отчётов перейдите в раздел «<a href="https://partners.zsquare.ru/reports/index">Отчёты</a>».</li>
                        <li>Выберите тип отчёта из выпадающего списка.</li>
                        <li>Укажите год и месяц.</li>
                        <li>Нажмите кнопку «Скачать отчёт» для получения отчёта в доступном формате.</li>
                    </ul>
                </div>

                <div className="section">
                    <h2>3. Управление аккаунтом</h2>

                    <img alt="Управление аккаунтом" src={require("./img_help/csv-50w.png")} />

                    <h3>3.1 Параметры аккаунта</h3>
                    <p>В разделе «<a href="https://partners.zsquare.ru/panel/company/editcompany">Данные пользователя</a>» доступна информация об аккаунте оператора, а также можно изменить название компании и адрес.</p>

                    <p>Существует два типа аккаунтов:</p>
                    <ul>
                        <li><strong>Дистрибьютор</strong> — партнёр, который закупает лицензии у производителя и продаёт их реселлерам или конечным пользователям.</li>
                        <li><strong>Реселлер</strong> — компания или индивидуальный предприниматель, который покупает товары у дистрибьюторов и перепродаёт их конечным пользователям.</li>
                    </ul>
                    <p>Тип аккаунта зависит от деталей договора и не может быть изменён в панели управления.</p>

                    <h3>3.2 Пользователи</h3>
                    <p>В разделе «<a href="https://partners.zsquare.ru/panel/company/usersmanegment">Пользователи</a>» вы можете добавлять и удалять пользователей, а также устанавливать для каждого пользователя права доступа.</p>
                    <p>На аккаунте оператора может быть один или несколько пользователей. Имеется два уровня доступа пользователей:</p>
                    <ul>
                        <li><strong>Менеджер</strong> — имеет доступ к функциям управления подписками и генерации отчётности.</li>
                        <li><strong>Администратор</strong> — помимо функций менеджера, имеет возможность создавать пользователей и изменять информацию о компании.</li>
                    </ul>
                </div>

            	<div className="section">
            		<h2>4. Управление текущим пользователем</h2>

            		<img alt="Управление текущим пользователем" src={require("./img_help/csv-49w.png")} />

            		<h3>4.1 Данные пользователя</h3>

            		<p>Для изменения данных текущего пользователя перейдите в раздел «<a href="https://partners.zsquare.ru/panel/personal">Данные пользователя</a>». Здесь вы можете изменить имя и телефон, а также установить новый пароль.</p>

            		<h3>4.2 Изменение пароля</h3>
            		<p>Для установки нового пароля в разделе «Личные данные» нажмите кнопку «Изменить пароль». Введите новый пароль, который желаете установить, и подтвердите изменение нажатием кнопки «Сменить пароль».</p>

            		<h3>4.3 Параметры интерфейса</h3>
            		<p>Панель управления предоставляет возможность настроить отображение таблицы подписок в разделе «<a href="https://partners.zsquare.ru/backoffice/tablesettings">Настройки таблицы</a>». Здесь вы можете выбрать, какие поля будут отображаться в таблице.</p>
            	</div>
            </div>
        </div>
    )
}

export default App
