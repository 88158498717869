import React from 'react'
import {Route, Routes} from 'react-router-dom'
import ULink from "./uLink"
import './App.css'
import URest from './docRest'
import UBo from './docBo'

function App() {
    return <Routes>
        <Route path="/doc-gui/*" element={<UBo />} />
        <Route path="/doc-gui" element={<UBo />} />
        <Route path="/doc-rest/*" element={<URest />} />
        <Route path="/doc-rest" element={<URest />} />
        <Route path="/*" element={<ULink />} />
    </Routes>
}

export default App
