import React, {useState} from "react"
import "@andypf/json-viewer"
import JsonViewer from "@andypf/json-viewer/dist/esm/react/JsonViewer"

function App() {

    const [eventPage, setEventPage] = useState(false)
    const [searchValue, setSearchValue] = useState(0)
    const [oneReq, setOneReq] = useState(false)
    const [wWidth, setWWidth] = useState(0)
    const [wHeight, setWHeight] = useState(0)
    const [sPool, setSPool] = useState({"data": false, "blocks": {}})
    const ShowJson = true
    //const JsonViewer = (myData) => <div></div>
    //const JsonInHtml = (myData) => <div></div>
    const JsonViewer = ({ data, ...props }) => {const ref = React.useRef(null);React.useEffect(() => {if (ref.current) {ref.current.data = data}}, [data]);return <andypf-json-viewer ref={ref} {...props} />}
    const JsonInHtml = (myData) => <JsonViewer theme="brushtrees-dark" expanded="true" indent="2" expand-icon-type="circle" show-data-types="false" show-toolbar="false" show-size="false" show-copy="true" data={myData.props} />// <JsonInHtml props={jsonExample} />// let jsonExample = {"1212": "234234"}
    function getTime () {return (new Date).getTime()}
    let timeLock = getTime() + 500
    let internalRouter = document.location.pathname.split("/").join("")
    let mainData = {}

    // 1. internal router
    if (internalRouter.substr(0,8) === "doc-rest") {
        mainData = {
            blocks: [
                {
                    name: 'SDS',
                    blocks: [
                        {
                            name: '',
                            blocks: [
                                {type: "title", title: ""
                                +'REST API Documentation for SDS project.'
                                +'<ul>'
                                +'<li>All API GET requests can be filtered by <r>filter</r> parameter and cut by <r>limit</r> and <r>offset</r> query string parameters.</li>'
                                +'<li>All API GET requests of type <r>/&#8249;entity&#8250;/:id</r> or <r>/&#8249;entity&#8250;</r> return same entities objects but in array in second case.</li>'
                                +'<li>All API GET array of entities requests supports <r>/count</r> addon which returns number of entities found (also supports filters).</li>'
                                +'<li>API support <r>json query string</r> query string format: var URI = "/users?filter[login]=system"; will be equal to: var uriParams = { filter: { login: "system" } }; var URI = "/users?" + encodeURIComponent(JSON.stringify(uriParams)); In second case filters like <r>true</r> and <r>null</r> can be correctly submitted.</li>'
                                +'<li>POST/PUT requests must be send with Content-type: application/json and json string in the request body. Successfull requests return full modified entity object.</li>'
                                +'</ul>'
                                +""}
                            ],
                        },
                    ],
                },
                {
                    name: 'Quick Start',
                    blocks: [
                        {
                            name: '',
                            blocks: [
                                {type: "title", title: ""
                                +'<ul class="ulLink">'
                                +'<li><a href="#Auth - User authorization by login and password">Authorization</a></li>'
                                +'<li><a href="#Offers - Create offer">Get offers list</a></li>'
                                +'<li><a href="#Subscriptions - Create subscription">Create subscription</a></li>'
                                +'<li><a href="#Subscriptions - Modify subscription by ID">Modify subscription</a></li>'
                                +'<li><a href="#Subscriptions - Change subscription status by ID">Stop, resume and cancel subscription</a></li>'
                                +"</ul>"
                                +""}
                            ],
                        },
                    ],
                },
                {
                    name: 'Auth',
                    blocks: [
                        {
                            name: 'User authorization by<br/> login and password',
                            blocks: [
                                {type: "title", title: "API interface is protected by an authorization session. After sending an authorization request, the cookie returns SESSID = jnsdfn34r89neijfn9845fn49494v which must be sent with each subsequent request."},
                                {type: "parameter", title: "Request", table: {th: ['Type', 'Url', 'Permission'], tb: [['POST', 'https:// <server> .zsquare.ru/api/rest/auth', 'All']]}},
                                {type: "parameter", title: "Request Parameter", table: {th: ['Field', 'Type', 'Description'], tb: [
                                            ['login', 'String', 'Login of user'],
                                            ['password', 'String', 'Password of user'],
                                        ]}},
                                {type: "requestExample", title: "Request Example", code: {"login": "demo@zsquare.org", "password": "d6a4d050"}},
                                {type: "answer", title: "Response Parameter", table: {th: ['Field', 'Type', 'Description'], tb: [['accessToken', 'string', 'SESSID of current authorization session'], ['userId', 'string', 'UUID of authorized user']]}},
                                {type: "answerExample", title: "Response Example (Success)", preCode: "HTTP/1.1 200 OK", code: {"accessToken": "FWoyOH4PSK8swUfEqdLyRmXRgu1Lq7Bt", "userId": "0f39f0ea-0a7d-42e7-9e9d-e282a6e08d98"}},
                            ],
                        },
                        {
                            show: false,
                            name: 'Logout from current user<br/> session',
                            blocks: [
                                {type: "parameter", title: "Request", table: {th: ['Type', 'Url', 'Permission'], tb: [['DELETE', 'https:// <server> .zsquare.ru/api/rest/auth', 'All']]}},
                                {type: "answerExample", title: "Response Example (Success)", preCode: "HTTP/1.1 200 OK"},
                            ],
                        },
                    ],
                },
                {
                    name: 'Offers',
                    blocks: [
                        {
                            name: 'Create offer',
                            blocks: [
                                {type: "title", title: "Create new offer"},
                                {type: "parameter", title: "Request", table: {th: ['Type', 'Url', 'Permission'], tb: [['POST', 'https:// <server> .zsquare.ru/api/rest/offers', ' Administrator']]}},
                                {type: "parameter", title: "Request Parameter", table: {th: ['Field', 'Type', 'Description'], tb: [
                                            ['id', 'number', 'ID of offer'],
                                            ['name', 'String', 'Name of offer'],
                                            ['description', 'String', 'Description of offer'],
                                            ['options', 'Object', 'Options of offer'],
                                            ['createdAt', 'String', 'ISO Date string offer created'],
                                            ['updatedAt', 'String', 'ISO Date string offer updated'],
                                        ]}},
                                {type: "answerExample", title: "Response Example (Success)", preCode: "HTTP/1.1 201 OK", code: {
                                        "id": 1,
                                        "vendorCode": "KLM245AS3M",
                                        "name": "Offer",
                                        "description": "Test offer",
                                        "options": {
                                            "quantity": {
                                                "min": 5,
                                                "max": 9
                                            }
                                        },
                                        "createdAt": "2016-10-05T06:50:48.000Z",
                                        "updatedAt": "2016-10-05T06:50:48.000Z"
                                    }},
                                {type: "answer", title: "Response Example (Error 4xx)", table: {th: ['Name', 'Description'], tb: [['entityNotFoundError', 'Requested entity was not found']]}},
                                {type: "answerExample", preCode: "HTTP/1.1 404 Not Found"},
                            ],
                        },
                        {
                            name: 'Delete offer by ID',
                            blocks: [
                                {type: "title", title: "Create new offer"},
                                {type: "parameter", title: "Request", table: {th: ['Type', 'Url', 'Permission'], tb: [['DELETE', 'https:// <server> .zsquare.ru/api/rest/offers/:id', ' Administrator']]}},
                                {type: "parameter", title: "Request Parameter", table: {th: ['Field', 'Type', 'Description'], tb: [['id', 'number', 'ID of offer']]}},
                                {type: "answerExample", title: "Response Example (Success)", preCode: "HTTP/1.1 200 OK"},
                                {type: "answer", title: "Response Example (Error 4xx)", table: {th: ['Name', 'Description'], tb: [['entityNotFoundError', 'Requested entity was not found']]}},
                                {type: "answerExample", preCode: "HTTP/1.1 404 Not Found"},
                            ],
                        },
                        {
                            name: 'Get array of offers',
                            blocks: [
                                {type: "title", title: "This API method allows you to get a list of product offers tied to your account. Each product offer can contain one or more products. In the future, you will need the product offer ID or the product ID to receive the services. If no offers - returns empty array."},
                                {type: "parameter", title: "Request", table: {th: ['Type', 'Url', 'Permission'], tb: [['GET', 'https:// <server> .zsquare.ru/api/rest/offers?offset=0&limit=10', 'All']]}},
                                {type: "parameter", title: "Request Parameter", table: {th: ['Field', 'Type', 'Description'], tb: [
                                            ['offset', 'offset', 'Offset relative to the first element of the array'],
                                            ['limit', 'limit', 'The limit of the output elements of the array'],
                                        ]}},
                                {type: "parameter", title: "Response Parameter (Success)", table: {sp: {ps: '5-12'}, th: ['Field', 'Type', 'Description'], tb: [
                                            ['id', 'String', 'UUID of offer'],
                                            ['name', 'String', 'Name of offer'],
                                            ['description', 'String', 'Description of offer'],
                                            ['features', 'Object', 'Features of offer'],
                                            ['products', 'Object[]', 'Products of offer'],

                                            ['id', 'String', 'UUID of product'],
                                            ['name', 'String', 'Name of product'],
                                            ['vendorCode', 'String', 'Vendor code of product'],
                                            ['price', 'Number', 'Price of product'],

                                            ['currency', 'String', 'Price currency of product. The three-digit symbolic code for official currency names ISO 4217'],
                                            ['options', 'Object', 'Options of product. JSON Schema Validation object format'],
                                            ['createdAt', 'String', 'ISO Date string product created'],
                                            ['updatedAt', 'String', 'ISO Date string product updated'],

                                            ['createdAt', 'String', 'ISO Date string offer created'],
                                            ['updatedAt', 'String', 'ISO Date string offer updated'],
                                        ]}},
                                {type: "answerExample", title: "Response Example (Success)", preCode: "HTTP/1.1 200 OK", code: [{
                                        "id": "e77d6b46-fa76-44a3-b48e-868c16df74b5",
                                        "name": "Kaspersky Endpoint Security for Business - Basic Eastern Europe Edition 1 month Successive License",
                                        "description": null,
                                        "features": {},
                                        "products": [{
                                            "id": "47f6f188-95f1-4738-9edf-ad5b37e4b1c1",
                                            "name": "",
                                            "vendorCode": "KL4864OAMMG",
                                            "price": 0,
                                            "currency": "RUR",
                                            "options": {
                                                "type": "object",
                                                "required": [
                                                    "quantity"
                                                ],
                                                "properties": {
                                                    "quantity": {
                                                        "type": "integer",
                                                        "maximum": 19,
                                                        "minimum": 15
                                                    },
                                                    "billingPeriod": {
                                                        "type": "string",
                                                        "const": "1 months",
                                                        "default": "1 months"
                                                    }
                                                }
                                            },
                                            "createdAt": "2017-03-23T16:38:23.431Z",
                                            "updatedAt": "2017-03-23T16:39:51.630Z"
                                        }, {
                                            "id": "f8290e0f-22c0-4486-8a72-4501c7056378",
                                            "name": "",
                                            "vendorCode": "KL4864OAKMG",
                                            "price": 0,
                                            "currency": "RUR",
                                            "options": {
                                                "type": "object",
                                                "required": [
                                                    "quantity"
                                                ],
                                                "properties": {
                                                    "quantity": {
                                                        "type": "integer",
                                                        "maximum": 14,
                                                        "minimum": 10
                                                    },
                                                    "billingPeriod": {
                                                        "type": "string",
                                                        "const": "1 months",
                                                        "default": "1 months"
                                                    }
                                                }
                                            },
                                            "createdAt": "2017-03-23T16:38:23.431Z",
                                            "updatedAt": "2017-03-23T16:39:51.630Z"
                                        }, {
                                            "id": "3e3161b1-b83f-4acc-9ccb-a6f0407bbbe4",
                                            "name": "",
                                            "vendorCode": "KL4864OAEMG",
                                            "price": 0,
                                            "currency": "RUR",
                                            "options": {
                                                "type": "object",
                                                "required": [
                                                    "quantity"
                                                ],
                                                "properties": {
                                                    "quantity": {
                                                        "type": "integer",
                                                        "maximum": 9,
                                                        "minimum": 5
                                                    },
                                                    "billingPeriod": {
                                                        "type": "string",
                                                        "const": "1 months",
                                                        "default": "1 months"
                                                    }
                                                }
                                            },
                                            "createdAt": "2017-03-23T16:38:23.431Z",
                                            "updatedAt": "2017-03-23T16:39:51.630Z"
                                        }],
                                        "createdAt": "2017-03-21T21:31:36.452Z",
                                        "updatedAt": "2017-03-21T21:31:36.452Z"
                                    }]},

                            ],
                        },
                        {
                            name: 'Get offer by UUID',
                            blocks: [
                                {type: "title", title: "Get offer information by UUID. If not found - returns empty response."},
                                {type: "parameter", title: "Request", table: {th: ['Type', 'Url', 'Permission'], tb: [['GET', 'https:// <server> .zsquare.ru/api/rest/offers/:id', 'All']]}},
                                {type: "parameter", title: "Request Parameter", table: {th: ['Field', 'Type', 'Description'], tb: [['id', 'number', 'Offer UUID']]}},
                                {type: "parameter", title: "Response Parameter (Success)", table: {sp: {ps: '5-12'}, th: ['Field', 'Type', 'Description'], tb: [
                                            ['id', 'String', 'UUID of offer'],
                                            ['name', 'String', 'Name of offer'],
                                            ['description', 'String', 'Description of offer'],
                                            ['features', 'Object', 'Features of offer'],
                                            ['products', 'Object[]', 'Products of offer'],

                                            ['id', 'String', 'UUID of product'],
                                            ['name', 'String', 'Name of product'],
                                            ['vendorCode', 'String', 'Vendor code of product'],
                                            ['price', 'Number', 'Price of product'],

                                            ['currency', 'String', 'Price currency of product. The three-digit symbolic code for official currency names ISO 4217'],
                                            ['options', 'Object', 'Options of product. JSON Schema Validation object format'],
                                            ['createdAt', 'String', 'ISO Date string product created'],
                                            ['updatedAt', 'String', 'ISO Date string product updated'],

                                            ['createdAt', 'String', 'ISO Date string offer created'],
                                            ['updatedAt', 'String', 'ISO Date string offer updated'],
                                        ]}},
                                {type: "answerExample", title: "Response Example (Success)", preCode: "HTTP/1.1 200 OK", code: {
                                        "id": "e77d6b46-fa76-44a3-b48e-868c16df74b5",
                                        "name": "Kaspersky Endpoint Security for Business - Basic Eastern Europe Edition 1 month Successive License",
                                        "description": null,
                                        "features": {},
                                        "products": [{
                                            "id": "47f6f188-95f1-4738-9edf-ad5b37e4b1c1",
                                            "name": "",
                                            "vendorCode": "KL4864OAMMG",
                                            "price": 0,
                                            "currency": "RUR",
                                            "options": {
                                                "type": "object",
                                                "required": [
                                                    "quantity"
                                                ],
                                                "properties": {
                                                    "quantity": {
                                                        "type": "integer",
                                                        "maximum": 19,
                                                        "minimum": 15
                                                    },
                                                    "billingPeriod": {
                                                        "type": "string",
                                                        "const": "1 months",
                                                        "default": "1 months"
                                                    }
                                                }
                                            },
                                            "createdAt": "2017-03-23T16:38:23.431Z",
                                            "updatedAt": "2017-03-23T16:39:51.630Z"
                                        }],
                                        "createdAt": "2017-03-21T21:31:36.452Z",
                                        "updatedAt": "2017-03-21T21:31:36.452Z"
                                    }},
                            ],
                        },
                        {
                            name: 'Modify offers information<br/> by ID',
                            blocks: [
                                {type: "title", title: "If not found - returns empty response"},
                                {type: "parameter", title: "Request", table: {th: ['Type', 'Url', 'Permission'], tb: [['PUT', 'https:// <server> .zsquare.ru/api/rest/offers/:id', ' Administrator']]}},
                                {type: "parameter", title: "Request Parameter", table: {th: ['Field', 'Type', 'Description'], tb: [['id', 'number', 'Offer ID']]}},
                                {type: "parameter", title: "Response Parameter (Success)", table: {th: ['Field', 'Type', 'Description'], tb: [
                                            ['id', 'number', 'ID of offer'],
                                            ['name', 'String', 'Name of offer'],
                                            ['description', 'String', 'Description of offer'],
                                            ['options', 'Object', 'Options of offer'],
                                            ['createdAt', 'String', 'ISO Date string offer created'],
                                            ['updatedAt', 'String', 'ISO Date string offer updated'],
                                        ]}},
                                {type: "answerExample", title: "Response Example (Success)", preCode: "HTTP/1.1 200 OK", code: {
                                        "id": 1,
                                        "vendorCode": "KLM245AS3M",
                                        "name": "Offer",
                                        "description": "Test offer",
                                        "options": {
                                            "quantity": {
                                                "min": 5,
                                                "max": 9
                                            }
                                        },
                                        "createdAt": "2016-10-05T06:50:48.000Z",
                                        "updatedAt": "2016-10-05T06:50:48.000Z"
                                    }},
                                {type: "answer", title: "Response Example (Error 4xx)", table: {th: ['Name', 'Description'], tb: [['entityNotFoundError', 'Requested entity was not found']]}},
                                {type: "answerExample", preCode: "HTTP/1.1 404 Not Found"},


                            ],
                        },
                    ],
                },
                {
                    name: 'Subscriptions',
                    blocks: [
                        {
                            name: 'Create subscription',
                            blocks: [
                                {type: "title", title: "When requesting, one of the two \"offerId\" or \"productId\" fields is required. If the \"offerId\" field is sent, the system tries to find the desired product within the product offer based on the options parameters passed. If the productId field is passed, the required product is immediately selected.<br /><br />" +
                                        "The duration field contains the number of calculation periods (days, months or years) for each license issued for this product or null if the product is unlimited. The settlement period depends on the product, its value can be obtained upon receipt of the list of product offers.<br /><br />" +
                                        "Subscriptions with an calculation period of 1 day can only be created with an indefinite period. Subscriptions with an active period of a year or a month can only be created for 1 period or indefinitely.<br /><br />" +
                                        "The \"customer\" field contains information about the subscriber for this subscription. When the \"externalId\" field is sent, the system tries to find a subscriber by the ID passed to this field. If the subscriber is not found, the system tries to add a subscriber with this data.<br /><br />" +
                                        "<div class='titleFontRed'>Attention:</div> <div class='titleFontBlack'>The operation of adding a subscriber together with subscription creation must be enabled by the system administrator!!!</div>"},
                                {type: "parameter", title: "Request", table: {th: ['Type', 'Url', 'Permission'], tb: [['POST', 'https:// <server> .zsquare.ru/api/rest/subscriptions', 'All']]}},
                                {type: "parameter", title: "Request Parameter", table: {sp: {opt: [0,4,5,14,15,16,17,19,22,23,24,25,26,27], dep: [7], pd: [{d:2, r: ['6-26']}, {d:4, r: ['12-25']}, {d:6, r: ['13-19', '21-26']}]}, th: ['Field', 'Type', 'Description'], tb: [
                                            ['id', 'String', 'Subscription UUID'],
                                            ['offerId', 'String', 'Offer internal UUID'],
                                            ['productId', 'String', 'Product internal UUID'],
                                            ['duration', 'Number', 'Number of billing periods'],
                                            ['options', 'Object', 'Subscription options. Get by options from offer product'],

                                            ['customer', 'Object', 'Subscription customer info'],
                                            ['id', 'String', 'Subscription customer external UUID. Use customer.id parameter'],
                                            ['externalId', 'Object', 'Subscription customer external UUID. Use customer.id parameter'],
                                            ['parentId', 'Object', 'Parent of subscription customer'],
                                            ['name', 'String', 'Subscription customer title'],

                                            ['description', 'Object', 'Subscription customer description'],
                                            ['options', 'Object', 'Subscription customer options.'],
                                            ['contacts', 'Object', 'Subscription customer contacts'],
                                            ['name', 'String', 'Subscription customer contact name'],
                                            ['email', 'String', 'Subscription customer contact email. Email format (may be required for some products)'],

                                            ['website', 'String', 'Subscription customer contact website. Url format'],
                                            ['phone', 'String', 'Subscription customer contact phone'],
                                            ['zip', 'String', 'Subscription customer contact zip'],
                                            ['country', 'String', 'Subscription customer contact country. ISO 3166-1 Alpha 3 code, length – 3 symbols'],
                                            ['state', 'String', 'Subscription customer contact state'],

                                            ['company', 'Object', 'Subscription customer company'],
                                            ['name', 'String', 'Subscription customer company name'],
                                            ['code', 'String', 'Subscription customer company code'],
                                            ['email', 'String', 'Subscription customer company email. Email format (may be required for some products)'],
                                            ['website', 'String', 'Subscription customer company website. Url format'],

                                            ['phone', 'String', 'Subscription customer company phone'],
                                            ['fax', 'String', 'Subscription customer company fax'],
                                            ['customData', 'Object', 'Subscription custom data fields'],

                                        ]}},
                                {type: "requestExample", title: "Request Example", code: {
                                            "id": "be49955e-0cc0-44ec-a69c-7213aba965ff",
                                            "offerId": "1b71f54c-2565-423d-abb0-0030456b0c7b",
                                            "productId": "d6a4d050-d10d-47ac-93b8-85f4853145d9",
                                            "duration": 1,
                                            "options": {
                                                "quantity": 5
                                            },
                                            "customer": {
                                                "id": "93799cec-0288-11e7-93ae-92361f002671",
                                                "parentId": "fdc8b63a-5485-4471-b045-5b8c627b1a6a",
                                                "externalId": "e916ee89-e3cc-48d9-9e4f-c23fb689f1d0",
                                                "name": "Test customer",
                                                "description": "Test customer",
                                                "options": {
                                                    "contacts": {
                                                        "name": "Ivan Ivanov",
                                                        "email": "ivan.ivanov@mail.com",
                                                        "website": "http://my.mail.com",
                                                        "phone": "+7 (123) 321-12-12",
                                                        "city": "Moscow",
                                                        "zip": "123123",
                                                        "country": "RUS",
                                                        "state": "Moscow"
                                                    },
                                                    "company": {
                                                        "name": "My company",
                                                        "code": "123309127748",
                                                        "email": "support@mail.com",
                                                        "website": "http://mail.com",
                                                        "phone": "+7 (123) 321-12-12",
                                                        "fax": "+7 (123) 321-12-21"
                                                    }
                                                }
                                            },
                                            "customData": {}
                                        }},
                                {type: "parameter", title: "Response Parameter (Success)", preCode: "HTTP/1.1 201 OK", table: {sp: {ps: '10-14'}, th: ['Field', 'Type', 'Description'], tb: [
                                            ['id', 'String', 'UUID of subscription'],
                                            ['status', 'String', 'Status of subscription'],
                                            ['vendorCode', 'String', 'product vendor code'],
                                            ['productId', 'String', 'product internal id'],
                                            ['activation', 'Object', 'Subscription activation info object'],

                                            ['startTime', 'String', 'ISO Date string subscription has been started'],
                                            ['trialTime', 'String', 'ISO Date string trial perion has been expired'],
                                            ['endTime', 'String', 'ISO Date string subscription has been expired'],
                                            ['options', 'Object', 'Subscription options'],
                                            ['customer', 'Object', 'Subscription customer info'],

                                            ['id', 'Object', 'Subscription customer UUID'],
                                            ['externalId', 'Object', 'Subscription customer external UUID'],
                                            ['name', 'String', 'Subscription title'],
                                            ['description', 'Object', 'Subscription customer description'],
                                            ['options', 'Object', 'Subscription customer options'],

                                            ['customData', 'Object', 'Subscription custom data fields'],
                                            ['createdAt', 'String', 'ISO Date string subscription created'],
                                            ['updatedAt', 'String', 'ISO Date string subscription updated'],
                                        ]}},
                                {type: "answerExample", title: "Response Example (Success)", preCode: "HTTP/1.1 201 OK", code: {
                                        "id": "be49955e-0cc0-44ec-a69c-7213aba965ff",
                                        "status": "active",
                                        "vendorCode": "KSK24H123",
                                        "productId": "d6a4d050-d10d-47ac-93b8-85f4853145d9",
                                        "productName": "Kaspersky Endpoint Security for Business - Basic European Edition. 5-9 Node 1 month Successive MSP License",
                                        "activation": {
                                            "code": "ASDF-N24HF-AA35-4557"
                                        },
                                        "startTime": "2016-10-05T06:50:48.000Z",
                                        "endTime": "2016-10-05T06:50:48.000Z",
                                        "options": {
                                            "quantity": 5
                                        },
                                        "customer": {
                                            "id": "93799cec-0288-11e7-93ae-92361f002671",
                                            "externalId": "93799cec-0288-11e7-93ae-92361f002671",
                                            "name": "Test customer",
                                            "description": "Test customer",
                                            "options": {
                                                "contacts": {
                                                    "name": "Ivan Ivanov",
                                                    "email": "ivan.ivanov@mail.com",
                                                    "website": "http://my.mail.com",
                                                    "phone": "+7 (123) 321-12-12",
                                                    "city": "Moscow",
                                                    "zip": "123123",
                                                    "country": "RUS",
                                                    "state": "Moscow"
                                                },
                                                "company": {
                                                    "name": "My company",
                                                    "code": "123309127748",
                                                    "email": "support@mail.com",
                                                    "website": "http://mail.com",
                                                    "phone": "+7 (123) 321-12-12",
                                                    "fax": "+7 (123) 321-12-21"
                                                }
                                            }
                                        },
                                        "customData": {
                                        },
                                        "createdAt": "2016-10-05T06:50:48.000Z",
                                        "updatedAt": "2016-10-05T06:50:48.000Z"
                                    }},
                            ],
                        },
                        {
                            name: 'Modify subscription by ID',
                            blocks: [
                                {type: "title", title: "A subscription can change its expiration date by changing the <b>\"duration\"</b> parameter.<br/><br/>If the <b>\"duration\"</b> parameter is greater than <b>0</b>, the subscription expiration date increases by the specified number of billing periods. When the <b>\"duration\"</b> parameter is set to null, the subscription becomes unlimited. If the subscription is unlimited and the parameter <b>\"duration\"</b> is transmitted more than <b>0</b>, the subscription acquires the expiration date with the number of billing periods counted from the current date.<br/><br/>Subscriptions with a billing period of 1 day can only be renewed for an indefinite period. Subscriptions with an active period of a year or month can only be created for 1 term or indefinitely.<br/><br/>Also, the subscription options can be changed. Option values can be a combination of any product options of one product offer. Frequently, this option <b>\"quantity\"</b> means the number of devices for which license is issued.<br/><br/>If subscription not found with ID request then returns an empty response.<br/><br/>Any canceled or expired subscription can be renewed if you perform a subscription change by setting the \"duration\" parameter to the number of billing periods for which you want to renew the subscription."},
                                {type: "parameter", title: "Request", table: {th: ['Type', 'Url', 'Permission'], tb: [['PUT', 'https:// <server> .zsquare.ru/api/rest/subscriptions/:id', 'All']]}},
                                {type: "parameter", title: "Response Parameter (Success)", preCode: "HTTP/1.1 201 OK", table: {sp: {ps: '3-3', opt: [3]}, th: ['Field', 'Type', 'Description'], tb: [
                                           ['id', 'String', 'Subscription ID.'],
                                           ['duration', 'Number', 'number of billing periods'],
                                           ['options', 'Object', 'subscription options'],
                                           ['quantity', 'Number', 'number of devices for subscription'],
                                       ]}},
                                {type: "requestExample", title: "Request Example", code: {
                                    "offerId": "1b71f54c-2565-423d-abb0-0030456b0c7b",
                                    "productId": "d6a4d050-d10d-47ac-93b8-85f4853145d9",
                                    "duration": 1,
                                    "options": {"quantity": 5}
                                }},
                                {type: "answerExample", title: "Response Example (Success)", preCode: "HTTP/1.1 200 OK", code: {
                                    "id": "FWoyOH4PSK8swUfEqdLyRmXRgu1Lq7Bt",
                                    "status": "active",
                                    "vendorCode": "KSK24H123",
                                    "productId": "d6a4d050-d10d-47ac-93b8-85f4853145d9",
                                    "productName": "Kaspersky Endpoint Security for Business - Basic European Edition. 5-9 Node 1 month Successive MSP License",
                                    "activation": {
                                       "code": "ASDF-N24HF-AA35-4557"
                                    },
                                    "startTime": "2016-10-05T06:50:48.000Z",
                                    "endTime": "2016-10-05T06:50:48.000Z",
                                    "options": {
                                       "quantity": 5
                                    },
                                    "customer": {
                                       "id": "93799cec-0288-11e7-93ae-92361f002671",
                                       "name": "Test customer",
                                       "description": "Test customer",
                                       "options": {
                                         "contacts": {
                                           "name": "Ivan Ivanov",
                                           "email": "ivan.ivanov@mail.com",
                                           "website": "http://my.mail.com",
                                           "phone": "+7 (123) 321-12-12",
                                           "city": "Moscow",
                                           "zip": "123123",
                                           "country": "RUS",
                                           "state": "Moscow"
                                         },
                                         "company": {
                                           "name": "My company",
                                           "code": "123309127748",
                                           "email": "support@mail.com",
                                           "website": "http://mail.com",
                                           "phone": "+7 (123) 321-12-12",
                                           "fax": "+7 (123) 321-12-21"
                                         }
                                       }
                                    },
                                    "customData": {},
                                    "createdAt": "2016-10-05T06:50:48.000Z",
                                    "updatedAt": "2016-10-05T06:50:48.000Z"
                                  }},
                                {type: "parameter", title: "Response Parameter (Success)", preCode: "HTTP/1.1 201 OK", table: {sp: {ps: '10-14'}, th: ['Field', 'Type', 'Description'], tb: [
                                    ['id', 'String', 'ID of subscription'],
                                    ['status', 'String', 'Status of subscription'],
                                    ['vendorCode', 'String', 'product vendor code'],
                                    ['productId', 'String', 'product internal id'],
                                    ['activation', 'Object', 'Subscription activation info object'],

                                    ['startTime', 'String', 'ISO Date string subscription has been started'],
                                    ['trialTime', 'String', 'ISO Date string trial perion has been expired'],
                                    ['endTime', 'String', 'ISO Date string subscription has been expired'],
                                    ['options', 'Object', 'Subscription options'],
                                    ['customer', 'Object', 'Subscription customer info'],

                                    ['id', 'Object', 'Subscription customer UUID'],
                                    ['externalId', 'Object', 'Subscription customer external UUID'],
                                    ['name', 'String', 'Subscription title'],
                                    ['description', 'Object', 'Subscription customer description'],
                                    ['options', 'Object', 'Subscription customer options'],

                                    ['customData', 'Object', 'Subscription custom data fields'],
                                    ['createdAt', 'String', 'ISO Date string subscription created'],
                                    ['updatedAt', 'String', 'ISO Date string subscription updated'],
                                ]}},
                            ],
                        },
                        {
                            name: 'Change subscription<br/> status by ID',
                            blocks: [
                                {type: "title", title: "A subscription can have one of several statuses: <ul><li>active - normal subscription status</li><li>paused - a subscription is paused. This state can only be used for some subscriptions, since it directly depends on the capabilities of the service provider</li><li>stopped - a subscription is stopped. This status is applied in those cases when the user needs to be warned about the need to renew the subscription. Some products may issue alerts when the subscription is in this status. The subscription behavior in this status depends on the capabilities of the service provider</li><li>canceled - the subscription was forcibly canceled. This subscription can be extended</li><li>expired - the subscription was expired. This subscription can be extended</li></ul><br/><br/>Stopped or paused subscription can be extended by the \"resume\" command.<br/><br/>Expared or canceled subscription can be renewed (<a href=\"#Modify subscription by ID\">see section changing subscription options</a>)"},
                                {type: "parameter", title: "Request", table: {th: ['Type', 'Url', 'Permission'], tb: [['PUT', 'https:// <server> .zsquare.ru/api/rest/subscriptions/:id/:status', 'All']]}},
                                {type: "parameter", title: "Response Parameter (Success)", preCode: "HTTP/1.1 201 OK", table: {sp: {ps: '3-3', opt: [3]}, th: ['Field', 'Type', 'Description'], tb: [
                                    ['id', 'String', 'Subscription ID.'],
                                    ['duration', 'String', 'Status of subscription.<br/> Allowed values: <r>\"pause\"</r>, <r>\"stop\"</r>, <r>\"resume\"</r>, <r>\"cancel\"<r> '],
                                ]}},
                                {type: "answerExample", title: "Response Example (Success)", preCode: "HTTP/1.1 200 OK", code: {
                                                                "id": "FWoyOH4PSK8swUfEqdLyRmXRgu1Lq7Bt",
                                                                "status": "active",
                                                                "vendorCode": "KSK24H123",
                                                                "productId": "d6a4d050-d10d-47ac-93b8-85f4853145d9",
                                                                "productName": "Kaspersky Endpoint Security for Business - Basic European Edition. 5-9 Node 1 month Successive MSP License",
                                                                "activation": {
                                                                   "code": "ASDF-N24HF-AA35-4557"
                                                                },
                                                                "startTime": "2016-10-05T06:50:48.000Z",
                                                                "endTime": "2016-10-05T06:50:48.000Z",
                                                                "options": {
                                                                   "quantity": 5
                                                                },
                                                                "customer": {
                                                                   "id": "93799cec-0288-11e7-93ae-92361f002671",
                                                                   "name": "Test customer",
                                                                   "description": "Test customer",
                                                                   "options": {
                                                                     "contacts": {
                                                                       "name": "Ivan Ivanov",
                                                                       "email": "ivan.ivanov@mail.com",
                                                                       "website": "http://my.mail.com",
                                                                       "phone": "+7 (123) 321-12-12",
                                                                       "city": "Moscow",
                                                                       "zip": "123123",
                                                                       "country": "RUS",
                                                                       "state": "Moscow"
                                                                     },
                                                                     "company": {
                                                                       "name": "My company",
                                                                       "code": "123309127748",
                                                                       "email": "support@mail.com",
                                                                       "website": "http://mail.com",
                                                                       "phone": "+7 (123) 321-12-12",
                                                                       "fax": "+7 (123) 321-12-21"
                                                                     }
                                                                   }
                                                                },
                                                                "customData": {},
                                                                "createdAt": "2016-10-05T06:50:48.000Z",
                                                                "updatedAt": "2016-10-05T06:50:48.000Z"
                                                              }},
                                {type: "parameter", title: "Response Parameter (Success)", preCode: "HTTP/1.1 201 OK", table: {sp: {ps: '10-14'}, th: ['Field', 'Type', 'Description'], tb: [
                                                                ['id', 'String', 'ID of subscription'],
                                                                ['status', 'String', 'Status of subscription'],
                                                                ['vendorCode', 'String', 'product vendor code'],
                                                                ['productId', 'String', 'product internal id'],
                                                                ['activation', 'Object', 'Subscription activation info object'],

                                                                ['startTime', 'String', 'ISO Date string subscription has been started'],
                                                                ['trialTime', 'String', 'ISO Date string trial perion has been expired'],
                                                                ['endTime', 'String', 'ISO Date string subscription has been expired'],
                                                                ['options', 'Object', 'Subscription options'],
                                                                ['customer', 'Object', 'Subscription customer info'],

                                                                ['id', 'Object', 'Subscription customer UUID'],
                                                                ['externalId', 'Object', 'Subscription customer external UUID'],
                                                                ['name', 'String', 'Subscription title'],
                                                                ['description', 'Object', 'Subscription customer description'],
                                                                ['options', 'Object', 'Subscription customer options'],

                                                                ['customData', 'Object', 'Subscription custom data fields'],
                                                                ['createdAt', 'String', 'ISO Date string subscription created'],
                                                                ['updatedAt', 'String', 'ISO Date string subscription updated'],
                                                            ]}},
                            ],
                        },
                        {
                            name: 'Get subscription<br/> download links by ID',
                            blocks: [
                                {type: "title", title: "Some subscriptions may have links to download a software. This API allows you to get a list of links to downloading software for the subscription.<br/><br/><div class='titleFontRed'>Attention:</div> The possibility of obtaining links to download a software for subscriptions should be check with your support manager."},
                                {type: "parameter", title: "Request", table: {th: ['Type', 'Url', 'Permission'], tb: [['GET', 'https://<server>.zsquare.ru/api/rest/subscriptions/:id/download', 'All']]}},
                                {type: "parameter", title: "Response Parameter (Success)", preCode: "HTTP/1.1 201 OK", table: {th: ['Field', 'Type', 'Description'], tb: [
                                    ['id', 'String', 'Subscription ID.'],
                                ]}},
                                {type: "answerExample", title: "Response Example (Success)", preCode: "HTTP/1.1 200 OK", code: [
                                    {"name": "Kaspersky Safe Kid for Windows", "url": "https://products.europe.kss.kaspersky-labs.com/download/56a62fab-6c60-46e8-ab10-af5628c05c88", "language": "ru", "platform": "Windows"},
                                    {"name": "Kaspersky Safe Kid for Mac", "url": "https://products.europe.kss.kaspersky-labs.com/download/aa0e8ed3-46b6-4019-9b7d-e710d9fde179", "language": "ru", "platform": "macOS"},
                                    {"name": "Kaspersky Safe Kids for Android", "url": "market://details?id=com.kaspersky.safekids", "language": "ru", "platform": "Android"},
                                    {"name": "Kaspersky Safe Kids for iOS","url": "https://itunes.apple.com/app/id967986300", "language": "ru", "platform": "iOS"}
                                ]},
                                {type: "parameter", title: "Response Parameter (Success)", preCode: "HTTP/1.1 201 OK", table: {sp: {ps: '10-14'}, th: ['Field', 'Type', 'Description'], tb: [
                                    ['name', 'String', 'Name of product download link'],
                                    ['url', 'String', 'Product download link'],
                                    ['language', 'String', 'Localization language for downloaded product'],
                                    ['platform', 'Object', 'Platform type for downloaded product'],
                                ]}},
                            ],
                        },
                        {
                            name: 'Get array of<br/> subscriptions',
                            blocks: [
                                {type: "title", title: "If no subscriptions - returns empty array"},
                                {type: "parameter", title: "Request", table: {th: ['Type', 'Url', 'Permission'], tb: [['GET', 'https://<server>.zsquare.ru/api/rest/subscriptions?offset=0&limit=10', 'All']]}},
                                {type: "answerExample", title: "Response Example (Success)", preCode: "HTTP/1.1 200 OK", code: [{
                                  "id": "FWoyOH4PSK8swUfEqdLyRmXRgu1Lq7Bt",
                                  "status": "active",
                                  "vendorCode": "KSK24H123",
                                  "productId": "d6a4d050-d10d-47ac-93b8-85f4853145d9",
                                  "productName": "Kaspersky Endpoint Security for Business - Basic European Edition. 5-9 Node 1 month Successive MSP License",
                                  "activation": {
                                     "code": "ASDF-N24HF-AA35-4557"
                                  },
                                  "startTime": "2016-10-05T06:50:48.000Z",
                                  "endTime": "2016-10-05T06:50:48.000Z",
                                  "options": {
                                     "quantity": 5
                                  },
                                  "customer": {
                                     "id": "93799cec-0288-11e7-93ae-92361f002671",
                                     "name": "Test customer",
                                     "description": "Test customer"
                                  },
                                  "customData": {
                                  },
                                  "createdAt": "2016-10-05T06:50:48.000Z",
                                  "updatedAt": "2016-10-05T06:50:48.000Z"
                                }]},
                                {type: "parameter", title: "Response Parameter (Success)", preCode: "HTTP/1.1 201 OK", table: {sp: {ps: '10-14'}, th: ['Field', 'Type', 'Description'], tb: [
                                    ['id', 'String', 'ID of subscription'],
                                    ['status', 'String', 'Status of subscription'],
                                    ['vendorCode', 'String', 'product vendor code'],
                                    ['productId', 'String', 'product internal id'],
                                    ['activation', 'Object', 'Subscription activation info object'],

                                    ['startTime', 'String', 'ISO Date string subscription has been started'],
                                    ['trialTime', 'String', 'ISO Date string trial perion has been expired'],
                                    ['endTime', 'String', 'ISO Date string subscription has been expired'],
                                    ['options', 'Object', 'Subscription options'],
                                    ['customer', 'Object', 'Subscription customer info'],

                                    ['id', 'Object', 'Subscription customer UUID'],
                                    ['externalId', 'Object', 'Subscription customer external UUID'],
                                    ['name', 'String', 'Subscription title'],
                                    ['description', 'Object', 'Subscription customer description'],
                                    ['options', 'Object', 'Subscription customer options'],

                                    ['customData', 'Object', 'Subscription custom data fields'],
                                    ['createdAt', 'String', 'ISO Date string subscription created'],
                                    ['updatedAt', 'String', 'ISO Date string subscription updated'],
                                ]}},
                            ],
                        },
                        {
                            name: 'Get subscription<br/> information by ID',
                            blocks: [
                                {type: "title", title: "If not found - returns empty response"},
                                {type: "parameter", title: "Request", table: {th: ['Type', 'Url', 'Permission'], tb: [['GET', 'https://<server>.zsquare.ru/api/rest/subscriptions/:id', 'All']]}},
                                {type: "parameter", title: "Response Parameter (Success)", preCode: "HTTP/1.1 201 OK", table: {sp: {ps: '3-3', opt: [3]}, th: ['Field', 'Type', 'Description'], tb: [['id', 'String', 'Subscription ID']]}},
                                {type: "answerExample", title: "Response Example (Success)", preCode: "HTTP/1.1 200 OK", code: {
                                  "id": "FWoyOH4PSK8swUfEqdLyRmXRgu1Lq7Bt",
                                  "status": "active",
                                  "vendorCode": "KSK24H123",
                                  "productId": "d6a4d050-d10d-47ac-93b8-85f4853145d9",
                                  "productName": "Kaspersky Endpoint Security for Business - Basic European Edition. 5-9 Node 1 month Successive MSP License",
                                  "activation": {
                                     "code": "ASDF-N24HF-AA35-4557"
                                  },
                                  "startTime": "2016-10-05T06:50:48.000Z",
                                  "endTime": "2016-10-05T06:50:48.000Z",
                                  "options": {
                                     "quantity": 5
                                  },
                                  "customer": {
                                     "id": "93799cec-0288-11e7-93ae-92361f002671",
                                     "name": "Test customer",
                                     "description": "Test customer",
                                     "options": {
                                       "contacts": {
                                         "name": "Ivan Ivanov",
                                         "email": "ivan.ivanov@mail.com",
                                         "website": "http://my.mail.com",
                                         "phone": "+7 (123) 321-12-12",
                                         "city": "Moscow",
                                         "zip": "123123",
                                         "country": "RUS",
                                         "state": "Moscow"
                                       },
                                       "company": {
                                         "name": "My company",
                                         "code": "123309127748",
                                         "email": "support@mail.com",
                                         "website": "http://mail.com",
                                         "phone": "+7 (123) 321-12-12",
                                         "fax": "+7 (123) 321-12-21"
                                       }
                                     }
                                  },
                                  "customData": {
                                  },
                                  "createdAt": "2016-10-05T06:50:48.000Z",
                                  "updatedAt": "2016-10-05T06:50:48.000Z"
                                }},
                                {type: "parameter", title: "Response Parameter (Success)", preCode: "HTTP/1.1 201 OK", table: {sp: {ps: '10-14'}, th: ['Field', 'Type', 'Description'], tb: [
                                    ['id', 'String', 'ID of subscription'],
                                    ['status', 'String', 'Status of subscription'],
                                    ['vendorCode', 'String', 'product vendor code'],
                                    ['productId', 'String', 'product internal id'],
                                    ['activation', 'Object', 'Subscription activation info object'],

                                    ['startTime', 'String', 'ISO Date string subscription has been started'],
                                    ['trialTime', 'String', 'ISO Date string trial perion has been expired'],
                                    ['endTime', 'String', 'ISO Date string subscription has been expired'],
                                    ['options', 'Object', 'Subscription options'],
                                    ['customer', 'Object', 'Subscription customer info'],

                                    ['id', 'Object', 'Subscription customer UUID'],
                                    ['externalId', 'Object', 'Subscription customer external UUID'],
                                    ['name', 'String', 'Subscription title'],
                                    ['description', 'Object', 'Subscription customer description'],
                                    ['options', 'Object', 'Subscription customer options'],

                                    ['customData', 'Object', 'Subscription custom data fields'],
                                    ['createdAt', 'String', 'ISO Date string subscription created'],
                                    ['updatedAt', 'String', 'ISO Date string subscription updated'],
                                ]}},
                            ],
                        },
                    ],
                },
                {
                    name: 'My Profile',
                    blocks: [
                        {
                            name: 'Get profile for current<br/> logged user',
                            blocks: [
                                {type: "title", title: "Get profile for current logged user"},
                                {type: "parameter", title: "Request", table: {th: ['Type', 'Url', 'Permission'], tb: [['GET', 'https://<server>.zsquare.ru/api/rest/me', 'All']]}},
                                {type: "parameter", title: "Response Parameter (Success)", preCode: "HTTP/1.1 201 OK", table: {sp: {ps: '3-3', opt: [3]}, th: ['Field', 'Type', 'Description'], tb: [['object', 'object', 'Object of permissions']]}},
                                {type: "answerExample", title: "Response Example (Success)", preCode: "HTTP/1.1 200 OK", code: {"id": 1, "login": "admin@zsquare.org", "enabled": true, "createdAt": "2016-12-03T17:04:12.000Z", "updatedAt": "2016-12-03T17:04:12.000Z"}},
                                {type: "parameter", title: "Response Parameter (Success)", preCode: "HTTP/1.1 201 OK", table: {sp: {ps: '10-14'}, th: ['Field', 'Type', 'Description'], tb: [['object', 'object', 'Object of permissions']]}},
                                {type: "answerExample", title: "Response Example (Error)", preCode: "HTTP/1.1 4XX"},
                                {type: "parameter", title: "Response Parameter (Error)", preCode: "HTTP/1.1 4XX", table: {th: ['Name', 'Description'], tb: [['entityNotFoundError', 'Requested entity was not found']]}},
                            ],
                        },
                    ],
                }
            ]
        }
    }

    const DrawTableApi = (table) => {
        let outVal =  <table className="DrawTableApi" cellSpacing="1">
            <thead>
            <tr>
                {
                    Object.keys(table.table.th).map((item, index) => {
                        return <td key={index}><b>{table.table.th[item]}</b></td>
                    })
                }
            </tr>
            </thead>
            <tbody>
            {
                Object.keys(table.table.tb).map((item1, index1) => {
                    return <tr key={index1}>
                        {
                            Object.keys(table.table.tb[index1]).map((item2, index2) => {
                                let outText = table.table.tb[index1][item2]
                                if (outText.split('<server>').length > 1) {
                                    outText = outText.split('<server>').join('<g>&#8249;server&#8250;</g>')
                                }
                                let preSpaceClass = " " // preSpaceClass
                                if (index2 === 0 && !!table.table.sp) {
                                    if (!!table.table.sp.opt) {if ((table.table.sp.opt).indexOf(index1) > -1) {outText = outText + '<div class="paramTagG">optional</div>'}}
                                    if (!!table.table.sp.dep) {if ((table.table.sp.dep).indexOf(index1) > -1) {outText = outText + '<div class="paramTagR">deprecated</div>'}}
                                    if (!!table.table.sp.ps) {if (parseInt(table.table.sp.ps.split('-')[0]) <= index1 && parseInt(table.table.sp.ps.split('-')[1]) >= index1) {preSpaceClass = "preSpaceClass"}}
                                    if (!!table.table.sp.pd) {
                                        Object.keys(table.table.sp.pd).forEach((item3, index3) => {
                                            Object.keys(table.table.sp.pd[item3].r).forEach((item4, index4) => {
                                                if (parseInt(table.table.sp.pd[item3].r[item4].split('-')[0]) <= index1 && parseInt(table.table.sp.pd[item3].r[item4].split('-')[1]) >= index1) {
                                                    preSpaceClass = "preSpaceClass" + table.table.sp.pd[item3].d
                                                }
                                            })
                                        })
                                    }
                                }
                                return <td key={index2} className={preSpaceClass} dangerouslySetInnerHTML={{ __html: outText + '' }} />
                            })
                        }
                    </tr>
                })
            }
            </tbody>
        </table>
        return outVal
    }

    function getSizes(router){
        let glueObj = {"data": false}
        let glueKey = ''
        Object.keys(mainData.blocks).forEach((item1, index1) => {
            if (!!mainData.blocks[item1]){
                Object.keys(mainData.blocks[item1].blocks).map((item2, index2) => {
                    glueKey = mainData.blocks[item1].name + " - " + mainData.blocks[item1].blocks[index2].name.split('<br/>').join('')
                    if (document.getElementById(glueKey)) {
                        // glueObj[glueKey] = document.getElementById(glueKey).offsetTop
                        glueObj[glueKey] = Math.round(document.getElementById(glueKey).getBoundingClientRect().bottom + window.scrollY, 0)
                    }
                })
            }
        })
        if (Object.keys(glueObj).length > 0) {
            glueObj = {"data": true, "blocks": glueObj}
        }
        //console.log(glueObj.blocks)
        return glueObj
    }

    function rePos(router){
        let sPool = getSizes('rePos')
        let currentMarker = ''
        if (Object.keys(sPool.blocks).length > 0) {
            Object.keys(sPool.blocks).forEach((item1, index1) => {
                if (Math.round(window.scrollY) + 30 >= sPool.blocks[item1] + 0) {
                    currentMarker = item1
                }
            })

            let allTags = document.getElementsByClassName('menuPassive')
            for (let i = 0, len = allTags.length; i < len; i++) {
                allTags[i].classList.remove('menuActive')
            }
            currentMarker = currentMarker.split(" - ")
            if (currentMarker[1]) {
                currentMarker = currentMarker[1]
            }
            //console.log('add:' + currentMarker)

            let onePoint = document.getElementsByClassName('helpListMenuMenu__secondTitle')
            for (let i = 0, len = onePoint.length; i < len; i++) {
                if (allTags[i].textContent === currentMarker) {
                    document.getElementsByClassName('helpListMenuMenu__secondTitle')[i].parentElement.classList.add('menuActive')
                }
            }
        }
    }

    if (!eventPage) {
        setEventPage(true)
        //console.log('One: eventPage')

        // DOMContentLoaded
        // document.addEventListener("DOMContentLoaded", function () {})

        // scroll
        window.addEventListener('scroll', function(event) {if (timeLock <= getTime()) {rePos('scroll')}})

        // click
        // window.addEventListener('click', function(event) {})
    }

    function inputOnChange (router) {
        if (router === "clear") {
            document.getElementById("inputSearch").value = ""
        } else {
            let searchText = ("" + document.getElementById("inputSearch").value).toLowerCase()

            let allPoints = document.getElementsByClassName('helpListMenuMenu__secondTitle')
            for (let i = 0, len = allPoints.length; i < len; i++) {
                if (searchText !== "") {
                    if ((allPoints[i].innerHTML.split("<br>").join("").toLowerCase()).indexOf(searchText) > -1) {
                        // показать
                        allPoints[i].classList.remove("opacityHalf")
                    } else {
                        // скрыть
                        allPoints[i].classList.add("opacityHalf")
                    }
                } else {
                    // показать
                    allPoints[i].classList.remove("opacityHalf")
                }
            }

            // let allBlocks = document.getElementsByClassName('headBlock')
            // for (let i = 0, len = allBlocks.length; i < len; i++) {
            //     if (searchText !== "") {
            //         if ((allBlocks[i].innerHTML.split("<br>").join("").toLowerCase()).indexOf(searchText) > -1) {
            //             // показать
            //             allBlocks[i].classList.remove("opacityFull")
            //         } else {
            //             // скрыть
            //             allBlocks[i].classList.add("opacityFull")
            //         }
            //     } else {
            //         // показать
            //         allBlocks[i].classList.remove("opacityFull")
            //     }
            // }
        }
    }

    //console.log('Page: React-draw')

    return (
        <div className="App">
            <div className="helpList">
                <div className="helpListMenu">
                    <div className="helpListMenuSearch">
                        <div>
                            <input id="inputSearch" type="text" onChange={e => inputOnChange("input")} />
                            <div onClick={e => inputOnChange("clear")}>+</div>
                        </div>
                    </div>
                    <div className="helpListMenuList">
                        {
                            (mainData && mainData.blocks && Object.keys(mainData.blocks).length > 0) && Object.keys(mainData.blocks).map((item1, index1) => {
                                return <div key={index1}>

                                    {mainData.blocks[item1].blocks[0].name !== '' ? <div className="helpListMenuMenu__mainTitle">{mainData.blocks[item1].name}</div> : ''}

                                    <div>
                                        {!!mainData.blocks[item1] && Object.keys(mainData.blocks[item1].blocks).map((item2, index2) => {
                                            return <div key={index2}>
                                                {
                                                    (!!mainData.blocks[item1].blocks[index2] && mainData.blocks[item1].blocks[index2].name !== '') && <div id={"h" + index1 + "-m" + index2} className="menuPassive">
                                                        <a className="helpListMenuMenu__secondTitle" href={"#" + mainData.blocks[item1].name + " - " + mainData.blocks[item1].blocks[index2].name.split('<br/>').join('')} dangerouslySetInnerHTML={{__html: mainData.blocks[item1].blocks[index2].name + "" }} />
                                                    </div>
                                                }
                                            </div>
                                        })}
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
                <div className="helpListPage">
                    {(mainData && mainData.blocks && Object.keys(mainData.blocks).length > 0) && Object.keys(mainData.blocks).map((item1, index1) => {
                        return <div key={index1}>
                            <div className="helpListPage__mainTitle">{mainData.blocks[item1].name}</div>
                            <div>
                                {!!mainData.blocks[item1] && Object.keys(mainData.blocks[item1].blocks).map((item2, index2) => {
                                    return <div className={"headBlock"} id={!!mainData.blocks[item1].blocks[index2].name ? mainData.blocks[item1].blocks[index2].name.split('<br/>').join('') : ''} key={index2}>
                                        {
                                            (!!mainData.blocks[item1].blocks[index2]) && <div>
                                                {
                                                    !!mainData.blocks[item1].blocks[index2].name
                                                        ? <div id={mainData.blocks[item1].name + " - " + mainData.blocks[item1].blocks[index2].name.split('<br/>').join('')} className="helpListPage__secondTitle">{mainData.blocks[item1].name + " - " + mainData.blocks[item1].blocks[index2].name.split('<br/>').join('')}</div>
                                                        : ''
                                                }
                                                <div>
                                                    {!!mainData.blocks[item1].blocks[index2] && !!mainData.blocks[item1].blocks[index2].blocks && Object.keys(mainData.blocks[item1].blocks[index2].blocks).map((item3, index3) => {
                                                        return <div key={item3} className="helpListPage__blocks" id={"h" + index1 + "-b" + index2}>
                                                            <div>
                                                                {mainData.blocks[item1].blocks[index2].blocks[index3].type === 'title' && <div className="helpListPage__blockDescription" dangerouslySetInnerHTML={{ __html: mainData.blocks[item1].blocks[index2].blocks[index3].title }} />}
                                                            </div>
                                                            <div>
                                                                {mainData.blocks[item1].blocks[index2].blocks[index3].type === 'request' && <div>
                                                                    <div className="helpListPage__blockReqType">{mainData.blocks[item1].blocks[index2].blocks[index3].reqType}</div>
                                                                    <div className="helpListPage__blockReqUrl">{mainData.blocks[item1].blocks[index2].blocks[index3].reqUrl}</div>
                                                                    <div className="helpListPage__blockDescription">Permission: {mainData.blocks[item1].blocks[index2].blocks[index3].reqPerms}</div>
                                                                </div>}
                                                            </div>
                                                            <div>
                                                                {mainData.blocks[item1].blocks[index2].blocks[index3].type === 'parameter' && <div>
                                                                    <div className="helpListPage__blockParameter">{mainData.blocks[item1].blocks[index2].blocks[index3].title}:</div>
                                                                    <DrawTableApi table={mainData.blocks[item1].blocks[index2].blocks[index3].table}/>
                                                                </div>}
                                                            </div>
                                                            <div>
                                                                {mainData.blocks[item1].blocks[index2].blocks[index3].type === 'requestExample' && <div>
                                                                    <div className="helpListPage__blockParameter">{mainData.blocks[item1].blocks[index2].blocks[index3].title}:</div>
                                                                    <div>{ShowJson && !!mainData.blocks[item1].blocks[index2].blocks[index3].code &&  <JsonInHtml props={mainData.blocks[item1].blocks[index2].blocks[index3].code}/>}</div>
                                                                </div>}
                                                            </div>
                                                            <div>
                                                                {mainData.blocks[item1].blocks[index2].blocks[index3].type === 'answer' && <div>
                                                                    <div className="helpListPage__blockParameter">{mainData.blocks[item1].blocks[index2].blocks[index3].title}:</div>
                                                                    <DrawTableApi table={mainData.blocks[item1].blocks[index2].blocks[index3].table}/>
                                                                </div>}
                                                            </div>
                                                            <div>
                                                                {mainData.blocks[item1].blocks[index2].blocks[index3].type === 'answerExample' && <div>
                                                                    <div>{(!!mainData.blocks[item1].blocks[index2].blocks[index3].title && mainData.blocks[item1].blocks[index2].blocks[index3].title !== '') && <div className="helpListPage__blockParameter">{mainData.blocks[item1].blocks[index2].blocks[index3].title}:</div>}</div>
                                                                    <div className="helpListPage__blockReqType">{mainData.blocks[item1].blocks[index2].blocks[index3].preCode}</div>
                                                                    <div>{ShowJson && !!mainData.blocks[item1].blocks[index2].blocks[index3].code && <JsonInHtml props={mainData.blocks[item1].blocks[index2].blocks[index3].code}/>}</div>
                                                                </div>}
                                                            </div>
                                                        </div>
                                                    })}
                                                </div>
                                            </div>
                                        }
                                    </div>
                                })}
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default App
